import type { RawLocation } from 'vue-router/types/router';

export const REDIRECT_AUTH_URL_KEY = 'redirect_to';
export const STORAGE_INVITE_CODE_KEY = 'inviteCodeKey';
export const REFERRAL_CODE_KEY = 'referralCodeKey';
export const CART_ITEMS = 'cartItems';
export const SHOULD_SHOW_REFERRAL_MODAL = 'shouldShowReferralModal';
export const SHOULD_SHOW_PROMOTION_BANNER = 'shouldShowPromotionBanner';
export const SHOULD_SHOW_MOCKUP_SURVEY_MODAL = 'shouldShowMockupSurveyModal';
export const SHOULD_SHOW_AI_ONBOARDING_MODAL = 'shouldShowAIOnboardingModal';
export const SHOULD_SHOW_DOWNLOAD_CREDIT_COST_MODAL = 'shouldShowDownloadCreditCostModal';

export const AUTHENTICATION_FALLBACK_NAVIGATION_ROUTE: RawLocation = {
	name: 'LibraryExploreList',
	params: { resourceType: 'models' },
};
export const NON_AUTHENTICATED_ALLOW_ROUTE_NAMES = ['ResetPassword'];
