
	import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
	import { mapState } from 'vuex';
	import { NAMESPACE as EXPORT_NAMESPACE, IExportState, PixRenderExportStatus } from '@pixcap/ui-core/models/store/projectexports.interface';
	import PixRenderQueuePopup from '@pixcap/ui-core/components/download-queue/PixRenderQueuePopup.vue';
	import ButtonIcon from '@pixcap/ui-library/components/ButtonIcon.vue';
	import Popup from '@pixcap/ui-library/components/Popup.vue';
	import IconDownloadSimple from '@pixcap/ui-library/components/Icons/IconDownloadSimple.vue';
	import IconCheckMark from '@pixcap/ui-library/components/Icons/IconCheckMark.vue';
	import IconQueue from '@pixcap/ui-library/components/Icons/IconQueue.vue';
	import { CONTEXT_PROVIDER_TYPES } from '@pixcap/ui-core/models/widgets/contextProvider.interface';
	import IconLargeArrowLeft from '@pixcap/ui-library/components/Icons/IconLargeArrowLeft.vue';

	@Component({
		name: 'ServerDownloadPopup',
		components: {
			IconLargeArrowLeft,
			PixRenderQueuePopup,
			IconDownloadSimple,
			IconCheckMark,
			IconQueue,
			ButtonIcon,
			Popup,
		},
		computed: {
			...mapState(EXPORT_NAMESPACE, {
				isExporting: (state: IExportState) =>
					state.serverExportItems.some(
						(item) =>
							!item.isPreviewFileRender && (item.status === PixRenderExportStatus.IN_PROGRESS || item.status === PixRenderExportStatus.PREPARING)
					),
				countExportingItems: (state: IExportState) =>
					state.serverExportItems.filter(
						(item) =>
							!item.isPreviewFileRender && (item.status === PixRenderExportStatus.IN_PROGRESS || item.status === PixRenderExportStatus.PREPARING)
					).length,
			}),
		},
	})
	export default class ServerDownloadPopup extends Vue {
		@Prop({ default: 20 }) readonly iconSize: number;
		@Prop({ default: 'body' }) readonly container: string;

		isExporting: boolean;
		countExportingItems: boolean;
		isOpenedQueueWhenCompleted = true;

		$refs: {
			refServerDownloadPopup: Popup;
		};

		get showCompletedTooltip() {
			return !this.isExporting && !this.isOpenedQueueWhenCompleted;
		}

		@Watch('isExporting')
		onIsExportingChange(next) {
			if (next && this.isOpenedQueueWhenCompleted) this.isOpenedQueueWhenCompleted = false;
		}

		togglePopup() {
			if (!this.isExporting && !this.isOpenedQueueWhenCompleted) this.isOpenedQueueWhenCompleted = true;
			this.$refs.refServerDownloadPopup.toggle();
		}

		onDownloadPopupActionReceived(isClose = false) {
			if (isClose) this.$refs.refServerDownloadPopup.close();
			else this.$refs.refServerDownloadPopup.open();
		}

		mounted() {
			this.$pixcap.$widgets.contextProvider.registerProviderInjection(CONTEXT_PROVIDER_TYPES.SERVER_DOWNLOAD_POPUP, {
				callback: this.onDownloadPopupActionReceived.bind(this),
				callbackName: 'serverDownloadPopupToggle',
			});
		}
		beforeDestroy() {
			this.$pixcap.$widgets.contextProvider.deregisterProviderInjection(CONTEXT_PROVIDER_TYPES.SERVER_DOWNLOAD_POPUP, 'serverDownloadPopupToggle');
		}
	}
