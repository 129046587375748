
	import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
	import { actionsWrapper as ExportActions, mutationsWrapper as ExportMutations } from '@pixcap/ui-core/store/projectexports';
	import { PixRenderExportPayload, PixRenderExportStatus, PixRenderExportType } from '@pixcap/ui-core/models/store/projectexports.interface';
	import IconArrowSquareOut from '@pixcap/ui-library/components/Icons/IconArrowSquareOut.vue';
	import IconXCircle from '@pixcap/ui-library/components/Icons/IconXCircle.vue';
	import IconWarningFilled from '@pixcap/ui-library/components/Icons/IconWarningFilled.vue';
	import IconArrowCounterClockwise from '@pixcap/ui-library/components/Icons/IconArrowCounterClockwise.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import { NotificationType } from '@pixcap/ui-core/models/store/app.interface';
	import IconServerRender from '@pixcap/ui-library/components/Icons/IconServerRender.vue';
	import { mapGetters } from 'vuex';
	import { Getters as UserGetters, GetterTypes as UserGetterTypes, NAMESPACE as USER_NAMESPACE } from '@pixcap/ui-core/models/store/user.interface';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import { downloadDirectFromUrl } from '@pixcap/ui-core/utils/WindowUtils';
	@Component({
		name: 'ServerRenderItem',
		components: {
			BodyText,
			IconServerRender,
			IconArrowSquareOut,
			IconXCircle,
			IconArrowCounterClockwise,
			IconWarningFilled,
			CaptionText,
		},
		computed: {
			...mapGetters(USER_NAMESPACE, {
				isUnsubscribedUser: UserGetterTypes.IS_UNSUBSCRIBED_USER,
			}),
		},
	})
	export default class ServerRenderItem extends Vue {
		@Prop() renderItem: PixRenderExportPayload;

		isUnsubscribedUser: ReturnType<UserGetters[UserGetterTypes.IS_UNSUBSCRIBED_USER]>;

		PixRenderExportType = PixRenderExportType;

		get itemStatus() {
			return this.renderItem.status;
		}

		get isFinished() {
			return this.itemStatus === PixRenderExportStatus.COMPLETED || this.itemStatus === PixRenderExportStatus.DONE;
		}

		get isPreparing() {
			return this.itemStatus === PixRenderExportStatus.PREPARING;
		}

		get isCancelled() {
			return this.itemStatus === PixRenderExportStatus.CANCELLED;
		}

		get isInProgress() {
			return this.itemStatus === PixRenderExportStatus.IN_PROGRESS;
		}

		get isFailed() {
			return this.itemStatus === PixRenderExportStatus.FAILED;
		}

		get showProgress() {
			return this.renderItem.progress != null && !(this.isPreparing || this.isFailed || this.isFinished || this.isCancelled);
		}

		@Watch('itemStatus')
		onStatusChange(next, prev) {
			if (next === PixRenderExportStatus.COMPLETED && prev === PixRenderExportStatus.IN_PROGRESS) {
				setTimeout(() => {
					this.handleDownloadItem(false);
				}, 500);
			}
		}

		async handleDownloadItem(isManuallyDownloaded = true) {
			if (isManuallyDownloaded && this.renderItem.defaultDownloadUrl) {
				if (typeof this.renderItem.defaultDownloadUrl === 'string') {
					downloadDirectFromUrl(this.renderItem.defaultDownloadUrl, this.renderItem.fileName + '.' + this.renderItem.format.toLowerCase(), false);
				} else if (this.renderItem.defaultDownloadUrl.downloadFiles) {
					// 3d file response
					this.renderItem.defaultDownloadUrl.downloadFiles();
				}
				return;
			}

			const isDownloaded = await ExportActions.downloadServerRenderFileByRenderId(this.$store, {
				renderId: this.renderItem.renderId,
				downloadIfNotDownloadedAlready: !isManuallyDownloaded,
			});
			if (isDownloaded) {
				ExportActions.publishSceneToCommunity(this.$store, this.renderItem.renderId);
				const firstLetter = this.renderItem.exportType.charAt(0);
				const firstLetterCap = firstLetter.toUpperCase();
				const remainingLetters = this.renderItem.exportType.slice(1);
				const exportTypeCap = firstLetterCap + remainingLetters;
				this.$pixcap.$mixpanel.track('M-Download: download in recent download', {
					'Render Id': this.renderItem.renderId,
					Format: exportTypeCap,
					isManuallyDownloaded,
				});
			}
		}

		handleSelect() {
			//if (this.renderItem.exportType != PixRenderExportType.VIDEO) return
			ExportMutations.setSelectedServerExportItem(this.$store, this.renderItem.renderId);
		}

		handleRetryItem() {
			ExportActions.retryServerRenderExport(this.$store, {
				renderId: this.renderItem.renderId,
				format: this.renderItem.format,
				exportType: this.renderItem.exportType,
			});
		}

		handleCancelItem() {
			ExportMutations.setServerExportItemToCancel(this.$store, this.renderItem.renderId);
		}
	}
