
	import { Component, Vue } from 'vue-property-decorator';
	import { mapGetters, mapState } from 'vuex';
	import { NAMESPACE as EXPORT_NAMESPACE, IExportState, PixRenderExportStatus } from '@pixcap/ui-core/models/store/projectexports.interface';
	import ServerRenderItem from '@pixcap/ui-core/components/download-queue/ServerRenderItem.vue';
	import CaptionText from '@pixcap/ui-library/components/Typography/CaptionText.vue';
	import BodyText from '@pixcap/ui-library/components/Typography/BodyText.vue';
	import Button from '@pixcap/ui-library/components/Button/Button2.vue';
	import { _PIXCAP_ENV } from '@pixcap/ui-core/env';
	import IconArrowSquareOut from '@pixcap/ui-library/components/Icons/IconArrowSquareOut.vue';
	import { Getters as AuthGetters, GetterTypes as AuthGetterTypes, NAMESPACE as AUTH_NAMESPACE } from '@pixcap/ui-core/models/store/auth.interface';

	@Component({
		name: 'PixRenderQueuePopup',
		components: {
			IconArrowSquareOut,
			Button,
			ServerRenderItem,
			CaptionText,
			BodyText,
		},
		computed: {
			...mapState(EXPORT_NAMESPACE, {
				serverExportItems: (state: IExportState) => state.serverExportItems,
			}),
			...mapGetters(AUTH_NAMESPACE, {
				isAuthenticated: AuthGetterTypes.IS_AUTHENTICATED,
			}),
		},
	})
	export default class PixRenderQueuePopup extends Vue {
		serverExportItems: IExportState['serverExportItems'];
		isAuthenticated: ReturnType<AuthGetters[AuthGetterTypes.IS_AUTHENTICATED]>;
		emptyImage = `${_PIXCAP_ENV.ROOT_URL}/cdn/app/download-queue/empty-queue.webp`;

		get isEmptyRenderQueue() {
			return this.renderQueue.filter((item) => item.status != PixRenderExportStatus.REMOVED).length == 0;
		}

		get renderQueue() {
			return this.serverExportItems.filter((item) => !item.isPreviewFileRender);
		}
	}
