import { SUBSCRIPTION_TYPE, LocalisedPricing, UPGRADE_PLAN_TRIGGER_TYPE } from '@pixcap/ui-core/models/store/user.interface';
import { COUNTRY_CODES } from '@pixcap/ui-core/constants/user.constants';

// Do not call this function except from user getter getPricingForUser.
// getPricingForUser getter should be single gatekeeper for pricing.
export function getPricingByUserCountry(countryCode: string): LocalisedPricing {
	switch (countryCode) {
		// EURO
		case COUNTRY_CODES.ANDORRA:
		case COUNTRY_CODES.AUSTRIA:
		case COUNTRY_CODES.BELGIUM:
		case COUNTRY_CODES.CYPRUS:
		case COUNTRY_CODES.ESTONIA:
		case COUNTRY_CODES.FINLAND:
		case COUNTRY_CODES.FRANCE:
		case COUNTRY_CODES.GERMANY:
		case COUNTRY_CODES.GREECE:
		case COUNTRY_CODES.ITALY:
		case COUNTRY_CODES.LATVIA:
		case COUNTRY_CODES.LICHTENSTEIN:
		case COUNTRY_CODES.LUXEMBOURG:
		case COUNTRY_CODES.MALTA:
		case COUNTRY_CODES.MONACO:
		case COUNTRY_CODES.NETHERLANDS:
		case COUNTRY_CODES.PORTUGAL:
		case COUNTRY_CODES.SLOVAKIA:
		case COUNTRY_CODES.SLOVENIA:
		case COUNTRY_CODES.SPAIN:
			return {
				CURRENCY_PREFIX: '€',
				CURRENCY_CODE: 'EUR',
				PRO_MONTH: 14,
				PRO_ANNUAL: 111,
				ELITE_MONTH: 28,
				ELITE_ANNUAL: 224,
				CREDIT_BUNDLES: [
					{ CREDITS: 500, PRICE: 10 },
					{ CREDITS: 1000, PRICE: 19 },
					{ CREDITS: 1500, PRICE: 28 },
				],
			};
		// POUND
		case COUNTRY_CODES.UNITED_KINGDOM:
			return {
				CURRENCY_PREFIX: '£',
				CURRENCY_CODE: 'GBP',
				PRO_MONTH: 12,
				PRO_ANNUAL: 95,
				ELITE_MONTH: 24,
				ELITE_ANNUAL: 190,
				CREDIT_BUNDLES: [
					{ CREDITS: 500, PRICE: 8 },
					{ CREDITS: 1000, PRICE: 15 },
					{ CREDITS: 1500, PRICE: 22 },
				],
			};
		// Brazilian Real
		case COUNTRY_CODES.BRAZIL:
			return {
				CURRENCY_PREFIX: 'R$',
				CURRENCY_CODE: 'BRL',
				PRO_MONTH: 85,
				PRO_ANNUAL: 681,
				ELITE_MONTH: 170,
				ELITE_ANNUAL: 1362,
				CREDIT_BUNDLES: [
					{ CREDITS: 500, PRICE: 50 },
					{ CREDITS: 1000, PRICE: 90 },
					{ CREDITS: 1500, PRICE: 130 },
				],
			};
		case COUNTRY_CODES.AUSTRALIA:
			return {
				CURRENCY_PREFIX: 'A$',
				CURRENCY_CODE: 'AUD',
				PRO_MONTH: 22,
				PRO_ANNUAL: 180,
				ELITE_MONTH: 45,
				ELITE_ANNUAL: 360,
				CREDIT_BUNDLES: [
					{ CREDITS: 500, PRICE: 15 },
					{ CREDITS: 1000, PRICE: 28 },
					{ CREDITS: 1500, PRICE: 40 },
				],
			};
		case COUNTRY_CODES.CANADA:
			return {
				CURRENCY_PREFIX: 'CA$',
				CURRENCY_CODE: 'CAD',
				PRO_MONTH: 20,
				PRO_ANNUAL: 165,
				ELITE_MONTH: 40,
				ELITE_ANNUAL: 330,
				CREDIT_BUNDLES: [
					{ CREDITS: 500, PRICE: 15 },
					{ CREDITS: 1000, PRICE: 28 },
					{ CREDITS: 1500, PRICE: 40 },
				],
			};
		case COUNTRY_CODES.SINGAPORE:
			return {
				CURRENCY_PREFIX: 'SGD',
				CURRENCY_CODE: 'SGD',
				PRO_MONTH: 20,
				PRO_ANNUAL: 163,
				ELITE_MONTH: 40,
				ELITE_ANNUAL: 324,
				CREDIT_BUNDLES: [
					{ CREDITS: 500, PRICE: 15 },
					{ CREDITS: 1000, PRICE: 28 },
					{ CREDITS: 1500, PRICE: 40 },
				],
			};
		case COUNTRY_CODES.INDIA:
			return {
				CURRENCY_PREFIX: '₹',
				CURRENCY_CODE: 'INR',
				PRO_MONTH: 1250,
				PRO_ANNUAL: 10000,
				ELITE_MONTH: 2500,
				ELITE_ANNUAL: 20000,
				CREDIT_BUNDLES: [
					{ CREDITS: 500, PRICE: 830 },
					{ CREDITS: 1000, PRICE: 1600 },
					{ CREDITS: 1500, PRICE: 2370 },
				],
			};
		case COUNTRY_CODES.TURKEY:
			return {
				CURRENCY_PREFIX: '₺',
				CURRENCY_CODE: 'TRY',
				PRO_MONTH: 488,
				PRO_ANNUAL: 3909,
				ELITE_MONTH: 977,
				ELITE_ANNUAL: 7818,
				CREDIT_BUNDLES: [
					{ CREDITS: 500, PRICE: 310 },
					{ CREDITS: 1000, PRICE: 600 },
					{ CREDITS: 1500, PRICE: 890 },
				],
			};
		default:
			return {
				CURRENCY_PREFIX: '$',
				CURRENCY_CODE: 'USD',
				PRO_MONTH: 15,
				PRO_ANNUAL: 120,
				ELITE_MONTH: 30,
				ELITE_ANNUAL: 240,
				CREDIT_BUNDLES: [
					{ CREDITS: 500, PRICE: 10 },
					{ CREDITS: 1000, PRICE: 19 },
					{ CREDITS: 1500, PRICE: 28 },
				],
			};
	}
}

export const STRIPE_UNSUPPORTED_COUNTRIES = [
	COUNTRY_CODES.BRAZIL,
	COUNTRY_CODES.INDIA,
	COUNTRY_CODES.INDONESIA,
	COUNTRY_CODES.PAKISTAN,
	COUNTRY_CODES.PHILIPPINES,
	COUNTRY_CODES.TURKEY,
];

export const FREE_TRIAL_PLAN_INFO = {
	name: 'FREE Trial Plan.',
	price: 'FREE TRIAL',
	period: null,
	showChangePlan: true,
	showIcon: false,
	message: 'Get more, create more!',
};

export const CREDITS_REQUIRED_PER_SECOND_FOR_VIDEO_EXPORT = 10;

export const FREE_USER_MONTHLY_CREDITS = 30;
export const PRO_USER_MONTHLY_CREDITS = 600;
export const ELITE_USER_MONTHLY_CREDITS = 2000;

export const UPGRADE_FEATURE_SERVICES: {
	[subscriptionType: string]: {
		description: string;
		featureType: UPGRADE_PLAN_TRIGGER_TYPE | UPGRADE_PLAN_TRIGGER_TYPE[] | null;
	}[];
} = {
	[SUBSCRIPTION_TYPE.PROFESSIONAL]: [
		{
			description: `${PRO_USER_MONTHLY_CREDITS} monthly credits (Super Downloads & Pixcap AI)`,
			featureType: [
				UPGRADE_PLAN_TRIGGER_TYPE.RECHARGE_CREDITS,
				UPGRADE_PLAN_TRIGGER_TYPE.AI_STYLIST_EXCEED_LIMIT,
				UPGRADE_PLAN_TRIGGER_TYPE.DOWNLOAD_EXCEED_LIMIT,
			],
		},
		{ description: '4k resolution exports', featureType: UPGRADE_PLAN_TRIGGER_TYPE.HIGH_QUALITY_DOWNLOAD },
		{ description: 'AI image background removal', featureType: UPGRADE_PLAN_TRIGGER_TYPE.REMOVE_BACKGROUND },
		{ description: 'Custom artboard sizes', featureType: UPGRADE_PLAN_TRIGGER_TYPE.CUSTOM_BOARD_SIZE },
		{ description: 'Custom AI prompt', featureType: UPGRADE_PLAN_TRIGGER_TYPE.CUSTOM_AI_PROMPT },
		{ description: '3x-faster Super Downloads (image)', featureType: UPGRADE_PLAN_TRIGGER_TYPE.FASTER_RENDER },
		{ description: 'All premium templates & assets', featureType: UPGRADE_PLAN_TRIGGER_TYPE.PRO_ITEM_USE },
		{ description: 'Remove watermark', featureType: UPGRADE_PLAN_TRIGGER_TYPE.REMOVE_WATERMARK },
		{ description: 'Full commercial license', featureType: null },
	],
	[SUBSCRIPTION_TYPE.ELITE]: [
		{
			description: `${ELITE_USER_MONTHLY_CREDITS} monthly credits (Super Downloads & Pixcap AI)`,
			featureType: [
				UPGRADE_PLAN_TRIGGER_TYPE.RECHARGE_CREDITS,
				UPGRADE_PLAN_TRIGGER_TYPE.AI_STYLIST_EXCEED_LIMIT,
				UPGRADE_PLAN_TRIGGER_TYPE.DOWNLOAD_EXCEED_LIMIT,
			],
		},
		{ description: '4k resolution exports', featureType: UPGRADE_PLAN_TRIGGER_TYPE.HIGH_QUALITY_DOWNLOAD },
		{ description: 'AI image background removal', featureType: UPGRADE_PLAN_TRIGGER_TYPE.REMOVE_BACKGROUND },
		{ description: 'Custom artboard sizes', featureType: UPGRADE_PLAN_TRIGGER_TYPE.CUSTOM_BOARD_SIZE },
		{ description: 'Custom AI prompt', featureType: UPGRADE_PLAN_TRIGGER_TYPE.CUSTOM_AI_PROMPT },
		{ description: '3x-faster Super Downloads (image)', featureType: UPGRADE_PLAN_TRIGGER_TYPE.FASTER_RENDER },
		{ description: 'Export 3D files', featureType: UPGRADE_PLAN_TRIGGER_TYPE.GLB_DOWNLOAD },
		{ description: 'All premium templates & assets', featureType: UPGRADE_PLAN_TRIGGER_TYPE.PRO_ITEM_USE },
		{ description: 'Remove watermark', featureType: UPGRADE_PLAN_TRIGGER_TYPE.REMOVE_WATERMARK },
		{ description: 'Full commercial license', featureType: null },
	],
};
